import React, { useState, useEffect, useRef } from 'react';

const MidiBackground = ({ isApp = false }) => {
  const [notes, setNotes] = useState([]);
  const animationRef = useRef();

  useEffect(() => {
    const generateNotes = () => {
      return Array.from({ length: 30 }, () => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        width: Math.random() * 10 + 5,
        height: Math.random() * 30 + 10,
        isActive: false,
        activeDuration: 0,
        vx: (Math.random() - 0.5) * 0.01, // Reduced horizontal velocity
        vy: (Math.random() - 0.5) * 0.01, // Reduced vertical velocity
      }));
    };

    setNotes(generateNotes());
  }, []);

  useEffect(() => {
    const updateNotes = () => {
      setNotes(prevNotes => 
        prevNotes.map(note => {
          let { x, y, vx, vy, isActive, activeDuration } = note;
          
          // Update position
          x += vx;
          y += vy;
          
          // Bounce off edges
          if (x <= 0 || x >= 100) vx = -vx;
          if (y <= 0 || y >= 100) vy = -vy;
          
          // Update active state
          if (isActive) {
            activeDuration += 1/60; // Assuming 60 FPS
          } else if (Math.random() < 0.0005) { // Further reduced chance to activate
            isActive = true;
            activeDuration = 0;
          }
          
          return { ...note, x, y, vx, vy, isActive, activeDuration };
        })
      );
      animationRef.current = requestAnimationFrame(updateNotes);
    };

    animationRef.current = requestAnimationFrame(updateNotes);
    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  return (
    <div className={`fixed inset-0 ${isApp ? 'z-0 opacity-20' : 'z-0'}`}>
      <svg className={`w-full h-full ${isApp ? 'filter blur-sm' : 'filter blur-sm'}`}>
        {notes.map((note, index) => {
          const isActive = note.isActive && note.activeDuration < 7;
          return (
            <rect
              key={index}
              x={`${note.x}%`}
              y={`${note.y}%`}
              width={`${note.width}%`}
              height={`${note.height}px`}
              fill={isActive ? "#f05477" : "#000000"}
              rx="2"
              ry="2"
              opacity={isActive ? "0.6" : "0.3"}
            >
              <animate
                attributeName="fill"
                values={isActive ? "#000000;#f05477;#f05477;#f05477;#000000" : "#000000"}
                dur="7s"
                repeatCount="1"
                begin={isActive ? "0s" : "indefinite"}
              />
            </rect>
          );
        })}
      </svg>
    </div>
  );
};

export default MidiBackground;