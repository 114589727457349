import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "./ui/card";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { Mail, Loader2, AlertCircle, Bot, CheckCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import MidiBackground from './MidiBackground';
import { logEvent } from '../lib/amplitude';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    logEvent('Password Reset Requested', { email });

    try {
      await resetPassword(email);
      setSuccess(true);
    } catch (error) {
      console.error('Password reset error:', error);
      setError(error.message || 'An error occurred. Please try again.');
      logEvent('Password Reset Error', { error: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-transparent p-4 overflow-hidden">
      <MidiBackground isApp={false} />
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-lg z-10"
      >
        <div className="text-center mb-8 p-6 rounded-lg relative z-20">
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="inline-flex items-center text-4xl font-bold text-primary mb-2"
          >
            <Bot className="h-12 w-12 mr-2" />
            MidiMate
          </motion.div>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-lg text-muted-foreground mb-4"
          >
            Your AI-powered music companion
          </motion.p>
        </div>

        <Card className="w-full bg-white/70 backdrop-blur-md shadow-xl relative z-20">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-center">
              {success ? 'Reset Link Sent' : 'Forgot Password'}
            </CardTitle>
          </CardHeader>
          <CardContent className="px-8">
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Alert variant="destructive" className="mb-4">
                  <div className="flex items-center">
                    <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0" />
                    <AlertDescription>{error}</AlertDescription>
                  </div>
                </Alert>
              </motion.div>
            )}
            {success ? (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Alert className="mb-4">
                  <div className="flex items-center">
                    <CheckCircle className="h-4 w-4 mr-2 flex-shrink-0 text-green-500" />
                    <AlertDescription>
                      If an account exists for {email}, you will receive a password reset link.
                    </AlertDescription>
                  </div>
                </Alert>
                <Button 
                  variant="outline" 
                  onClick={() => navigate('/login')} 
                  className="w-full mt-4"
                >
                  Back to Login
                </Button>
              </motion.div>
            ) : (
              <form onSubmit={handleResetPassword} className="space-y-6">
                <div className="space-y-2">
                  <Label htmlFor="email" className="flex items-center">
                    <Mail className="mr-2 h-4 w-4" /> Email
                  </Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="text-base sm:text-sm transition-all duration-200 focus:ring-2 focus:ring-primary"
                  />
                </div>
                <Button type="submit" className="w-full" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Sending Reset Link...
                    </div>
                  ) : (
                    <>
                      <Mail className="mr-2 h-4 w-4" />
                      Send Reset Link
                    </>
                  )}
                </Button>
              </form>
            )}
          </CardContent>
          {!success && (
            <CardFooter className="flex justify-center">
              <Button 
                variant="link" 
                onClick={() => navigate('/login')} 
                className="w-full"
              >
                Remember your password? Login
              </Button>
            </CardFooter>
          )}
        </Card>
      </motion.div>
    </div>
  );
}

export default ForgotPassword;