import { init, track, setUserId as amplitudeSetUserId } from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;

const isProd = process.env.NODE_ENV === 'production';

let isInitialized = false;
let globalUserId = null;

export const initializeAmplitude = (userId) => {
  if (isProd && !isInitialized) {
    init(AMPLITUDE_API_KEY, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
      plugins: [
        sessionReplayPlugin({
          sampleRate: 1,
        }),
      ],
    });
    isInitialized = true;
  }
  if (userId) {
    setAmplitudeUserId(userId);
  }
};

export const setAmplitudeUserId = (userId) => {
  if (isProd) {
    amplitudeSetUserId(userId);
  }
  globalUserId = userId;
};

export const logEvent = (eventName, eventProperties = {}) => {
  const enhancedProperties = {
    ...eventProperties,
    userId: globalUserId,
  };

  if (isProd) {
    track(eventName, enhancedProperties);
  } else {
    console.log('Event logged (DEV):', eventName, enhancedProperties);
  }
};