import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { supabaseIntegration } from '@supabase/sentry-js-integration';
import { createClient } from '@supabase/supabase-js';

// Initialize your Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://a16fc1b3ac0f720c5d2af542ae5663a8@o4508072548696064.ingest.us.sentry.io/4508072549941248",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      supabaseIntegration(supabase, Sentry, {
        tracing: true,
        breadcrumbs: true,
        errors: true,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    debug: true,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
