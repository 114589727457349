import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '../lib/supabase';
import { setAmplitudeUserId } from '../lib/amplitude';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      setAmplitudeUserId(session?.user?.id || null);
      setLoading(false); 
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setSession(session);
        setLoading(false);
        setAmplitudeUserId(session?.user?.id || null);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const value = {
    signUp: (data) => supabase.auth.signUp(data),
    signIn: (data) => supabase.auth.signInWithPassword(data),
    signInWithGoogle: () => supabase.auth.signInWithOAuth({
      provider: 'google',
    }),
    signInWithApple: () => supabase.auth.signInWithOAuth({
      provider: 'apple',
    }),
    signOut: async () => {
      try {
        await supabase.auth.signOut();
      } catch (error) {
        console.error('Error during sign out:', error);
      } finally {
        // Force clear the session locally
        setSession(null);
        // Clear any stored tokens
        localStorage.removeItem('supabase.auth.token');
      }
    },
    resetPassword: (email) => {
      const config = {
        redirectTo: process.env.REACT_APP_BASE_URL 
          ? `${process.env.REACT_APP_BASE_URL}/reset-password`
          : `${window.location.origin}/reset-password`,
      };
      return supabase.auth.resetPasswordForEmail(email, config);
    },
    session,
    user: session?.user ?? null,
    loading,
    supabase,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
