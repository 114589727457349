import React from 'react';
import { RefreshCw, X, Loader2 } from 'lucide-react';
import { Button } from "./ui/button"
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "./ui/card"
import { logEvent } from '../lib/amplitude';

function ClearHistoryDialog({ isOpen, onClose, onClear, isClearing }) {
  if (!isOpen) return null;

  const handleClose = () => {
    logEvent('New Conversation Dialog Closed');
    onClose();
  };

  const handleClear = () => {
    logEvent('New Conversation Confirmed');
    onClear();
  };

  return (
    <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            <span>Clear Chat</span>
            <Button variant="ghost" size="icon" onClick={handleClose}>
              <X className="h-4 w-4" />
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center space-x-4 mb-4">
            <div className="bg-primary/10 p-3 rounded-full">
              <RefreshCw className="h-6 w-6 text-primary" />
            </div>
            <p className="text-muted-foreground flex-1">
              Ready for a fresh start? Clearing your chat history will give you and MidiMate a blank canvas to create new musical ideas together.
            </p>
          </div>
        </CardContent>
        <CardFooter className="flex justify-end space-x-2">
          <Button variant="outline" onClick={handleClose}>
            Keep Current Chat
          </Button>
          <Button 
            onClick={handleClear} 
            disabled={isClearing}
            className="bg-[#f05477] hover:bg-[#d03357] text-white transition-colors duration-300"
          >
            {isClearing ? <Loader2 className="h-4 w-4 animate-spin mr-2" /> : null}
            Clear Chat
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default ClearHistoryDialog;
