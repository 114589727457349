import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "./ui/card";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { Lock, Loader2, AlertCircle, Bot, LogIn } from 'lucide-react';
import { motion } from 'framer-motion';
import MidiBackground from './MidiBackground';
import { logEvent } from '../lib/amplitude';
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { supabase, signOut } = useAuth();
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    logEvent('Password Reset Attempt');
    try {
      const { data, error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
      setSuccess(true);
      logEvent('Password Reset Success');
      // Sign out the user after successful password reset
      await signOut();
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(error.message || 'Failed to reset password. Please try again.');
      logEvent('Password Reset Error', { error: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="relative flex flex-col items-center justify-center full-viewport-height bg-transparent p-4 overflow-hidden">
      <MidiBackground isApp={false} />
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-lg z-10"
      >
        <div className="text-center mb-8 p-6 rounded-lg relative z-20">
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="inline-flex items-center text-4xl font-bold text-primary mb-2"
          >
            <Bot className="h-12 w-12 mr-2" />
            MidiMate
          </motion.div>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-lg text-muted-foreground mb-4"
          >
            Your AI-powered music companion
          </motion.p>
        </div>

        <Card className="w-full bg-white/70 backdrop-blur-md shadow-xl relative z-20">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-center">
              {success ? "Password Reset Successful" : "Set New Password"}
            </CardTitle>
          </CardHeader>
          <CardContent className="px-8">
            {success ? (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Alert className="mb-4">
                  <AlertDescription className="text-center">
                    Your password has been successfully reset. Please log in with your new password.
                  </AlertDescription>
                </Alert>
                <Button onClick={handleNavigateToLogin} className="w-full">
                  <LogIn className="mr-2 h-4 w-4" />
                  Go to Login
                </Button>
              </motion.div>
            ) : (
              <>
                {error && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Alert variant="destructive" className="mb-4">
                      <div className="flex items-center">
                        <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0" />
                        <AlertDescription>{error}</AlertDescription>
                      </div>
                    </Alert>
                  </motion.div>
                )}
                <form onSubmit={handleResetPassword} className="space-y-6">
                  <div className="space-y-2">
                    <Label htmlFor="newPassword" className="flex items-center">
                      <Lock className="mr-2 h-4 w-4" /> New Password
                    </Label>
                    <Input
                      id="newPassword"
                      type="password"
                      placeholder="Enter your new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      className="text-base sm:text-sm transition-all duration-200 focus:ring-2 focus:ring-primary"
                    />
                  </div>
                  <Button type="submit" className="w-full" disabled={loading}>
                    {loading ? (
                      <div className="flex items-center justify-center">
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Resetting Password...
                      </div>
                    ) : (
                      <>
                        <Lock className="mr-2 h-4 w-4" />
                        Set New Password
                      </>
                    )}
                  </Button>
                </form>
              </>
            )}
          </CardContent>
          {!success && (
            <CardFooter className="flex justify-center">
              <Button 
                variant="link" 
                onClick={() => navigate('/login')} 
                className="w-full"
              >
                Remember your password? Login
              </Button>
            </CardFooter>
          )}
        </Card>
      </motion.div>
    </div>
  );
}

export default ResetPassword;