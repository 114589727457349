import React, { useRef } from 'react';
import { Bot } from 'lucide-react';
import { Button } from "./ui/button";
import { logEvent } from '../lib/amplitude';
import { Upload } from 'lucide-react';

function EmptyState({ suggestions, onSuggestionClick, context = 'midi' }) {
  const fileInputRef = useRef(null);

  const handleSuggestionClick = (suggestion) => {
    logEvent('Empty State Suggestion Clicked', { suggestion, context });
    onSuggestionClick(suggestion);
  };

  const handleUploadClick = () => {
    logEvent('Empty State Upload Button Clicked', { context });
    fileInputRef.current?.click();
  };

  React.useEffect(() => {
    logEvent('Empty State Viewed', { context });
  }, [context]);

  const getMessage = () => {
    switch (context) {
      case 'lyrics':
        return "Start by describing the lyrics you want to create or try one of these examples:";
      case 'midi':
      default:
        return "Start by describing the midi you want to create or try one of these examples:";
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      logEvent('Empty State MIDI File Selected', { 
        fileName: file.name,
        fileSize: file.size,
        context 
      });
    }
    onSuggestionClick(null, file);
  };

  return (
    <div className="flex flex-col items-center justify-center h-full text-center px-4">
      <Bot className="h-16 w-16 text-primary mb-6" />
      <h2 className="text-2xl font-bold mb-2">Welcome to MidiMate!</h2>
      <p className="text-gray-600 mb-8">{getMessage()}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-2xl w-full mb-8">
        {suggestions.map((suggestion, index) => (
          <Button
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
            variant="outline"
            size="lg"
            className="text-base py-3 px-6 h-auto border-2 border-primary hover:bg-primary hover:text-white transition-colors duration-300 shadow-md"
          >
            {suggestion}
          </Button>
        ))}
      </div>
      
      {context === 'midi' && (
        <div className="flex flex-col items-center">
          <p className="text-gray-600 mb-4">Or upload your own MIDI file and let MidiMate enhance it based on your instructions (ex. add chords, change key, etc):</p>
          <Button
            onClick={handleUploadClick}
            variant="outline"
            size="lg"
            className="text-base py-3 px-6 h-auto border-2 border-[#f05477] text-[#f05477] hover:bg-[#f05477] hover:text-white transition-colors duration-300 shadow-md flex items-center space-x-2"
          >
            <Upload className="h-5 w-5" />
            <span>Upload MIDI File</span>
          </Button>
        </div>
      )}
      
      <input
        ref={fileInputRef}
        type="file"
        accept=".mid,.midi"
        onChange={handleFileSelect}
        className="hidden"
      />
    </div>
  );
}

export default EmptyState;
