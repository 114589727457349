import { createClient } from '@supabase/supabase-js'

const isDevelopment = process.env.NODE_ENV === 'development';

const supabaseUrl = isDevelopment 
  ? 'http://localhost:54321'  // Local Supabase URL
  : process.env.REACT_APP_SUPABASE_URL;

const supabaseKey = isDevelopment
  ? process.env.REACT_APP_SUPABASE_ANON_KEY_LOCAL
  : process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey)