import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { Mail, Lock, LogIn, Loader2, AlertCircle, Bot, Info } from 'lucide-react';
import { motion } from 'framer-motion';
import MidiBackground from './MidiBackground';
import OnboardingCarousel from './OnboardingCarousel';
import { logEvent } from '../lib/amplitude';
import { useNavigate, Link } from 'react-router-dom';
import LegalLinks from './LegalLinks';
import PromotionalBanner from './PromotionalBanner';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { signIn, signInWithGoogle, signInWithApple, user } = useAuth();
  const [showCarousel, setShowCarousel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/app');
    }
  }, [user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      logEvent('Login Attempted', { email });
      const { error } = await signIn({ email, password });
      if (error) throw error;
      navigate('/app');
    } catch (error) {
      console.error('Authentication error', error);
      logEvent('Authentication Error', { error: error.message });
      if (error.message.includes('Invalid login credentials')) {
        setError('Incorrect email or password.');
      } else if (error.message.includes('Email not confirmed')) {
        setError('Please confirm your email address before logging in.');
      } else {
        setError(error.message || 'An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    logEvent('Google Sign In Attempted');
    try {
      const { error } = await signInWithGoogle();
      if (error) throw error;
      logEvent('Google Sign In Successful');
    } catch (error) {
      console.error('Error signing in with Google:', error);
      logEvent('Google Sign In Error', { error: error.message });
      setError(error.message || 'An error occurred during Google sign-in.');
    }
  };

  const handleAppleSignIn = async () => {
    logEvent('Apple Sign In Attempted');
    try {
      const { error } = await signInWithApple();
      if (error) throw error;
      logEvent('Apple Sign In Successful');
    } catch (error) {
      console.error('Error signing in with Apple:', error);
      logEvent('Apple Sign In Error', { error: error.message });
      setError(error.message || 'An error occurred during Apple sign-in.');
    }
  };

  const openHowItWorks = () => {
    logEvent('Learn More Opened');
    setShowCarousel(true);
  };

  const handleWrapperClick = (e) => {
    // Remove this line or comment it out
    // console.log('Wrapper clicked');
    // alert('Wrapper clicked');
  };

  return (
    <div className="relative flex flex-col items-center justify-center full-viewport-height bg-transparent p-4 overflow-hidden" onClick={handleWrapperClick}>
      <PromotionalBanner />
      <MidiBackground isApp={false} />
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-lg z-10"
      >
        <div className="text-center mb-8 p-6 rounded-lg relative z-20">
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="inline-flex items-center text-4xl font-bold text-primary mb-2"
          >
            <Bot className="h-12 w-12 mr-2" />
            MidiMate
          </motion.div>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-lg text-muted-foreground mb-4"
          >
            Your AI-powered music companion
          </motion.p>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="space-x-4"
          >
            <Button
              variant="outline"
              size="sm"
              onClick={openHowItWorks}
              className="bg-white/80 hover:bg-white/90 text-primary hover:text-primary-dark transition-colors"
            >
              <Info className="h-4 w-4 mr-2" />
              Learn More
            </Button>
          </motion.div>
        </div>

        <Card className="w-full bg-white/70 backdrop-blur-md shadow-xl relative z-20">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-center">Login</CardTitle>
          </CardHeader>
          <CardContent className="px-8">
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Alert variant="destructive" className="mb-4">
                  <div className="flex items-center">
                    <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0" />
                    <AlertDescription>{error}</AlertDescription>
                  </div>
                </Alert>
              </motion.div>
            )}
            <div className="space-y-4">
              <Button
                type="button"
                variant="outline"
                className="w-full border border-gray-300 hover:border-gray-400 bg-white text-gray-800 hover:bg-gray-50 flex items-center justify-center"
                onClick={handleGoogleSignIn}
              >
                <svg className="mr-2 h-5 w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                  <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                  <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                  <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                  <path d="M1 1h22v22H1z" fill="none"/>
                </svg>
                Continue with Google
              </Button>

              <Button
                type="button"
                variant="outline"
                className="w-full border border-gray-300 hover:border-gray-400 bg-white text-gray-800 hover:bg-gray-50 flex items-center justify-center transition-colors duration-200"
                onClick={handleAppleSignIn}
              >
                <svg className="mr-2 h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701"/>
                </svg>
                Continue with Apple
              </Button>

              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background px-2 text-muted-foreground">
                    Or
                  </span>
                </div>
              </div>

              <form onSubmit={handleLogin} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="email" className="flex items-center">
                    <Mail className="mr-2 h-4 w-4" /> Email
                  </Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="text-base sm:text-sm transition-all duration-200 focus:ring-2 focus:ring-primary"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="password" className="flex items-center">
                    <Lock className="mr-2 h-4 w-4" /> Password
                  </Label>
                  <Input
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="text-base sm:text-sm transition-all duration-200 focus:ring-2 focus:ring-primary"
                  />
                </div>
                <Button type="submit" className="w-full" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Processing...
                    </div>
                  ) : (
                    <>
                      <LogIn className="mr-2 h-4 w-4" /> Login
                    </>
                  )}
                </Button>
              </form>
            </div>
          </CardContent>
          <CardFooter className="flex flex-col space-y-2">
            <Button 
              variant="link" 
              onClick={() => navigate('/signup')} 
              className="w-full"
            >
              Need an account? Sign Up
            </Button>
            <Button 
              variant="link" 
              onClick={() => navigate('/forgot-password')} 
              className="w-full"
            >
              Forgot Password?
            </Button>
          </CardFooter>
        </Card>
      </motion.div>

      {showCarousel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <OnboardingCarousel onClose={() => setShowCarousel(false)} />
        </div>
      )}

      <LegalLinks /> {/* Add this line */}
    </div>
  );
}

export default Login;
