import React, { useEffect } from 'react';
import { CreditCard, X } from 'lucide-react';
import { Button } from "./ui/button"
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "./ui/card"
import { logEvent } from '../lib/amplitude';
import { useNavigate } from 'react-router-dom';

function OutOfCreditsDialog({ isOpen, onClose, isSubscriptionActive }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      logEvent('Out of Credits Dialog Opened', { isSubscriptionActive });
    }
  }, [isOpen, isSubscriptionActive]);

  if (!isOpen) return null;

  const title = isSubscriptionActive ? "Out of AI Messages" : "Subscribe to get more AI Messages";
  const message = isSubscriptionActive
    ? "You've run out of AI messages. Upgrade your plan to get more monthly messages, or wait for your messages to replenish on your next subscription renewal date."
    : "You've run out of AI messages. Subscribe to get monthly messages, automatically replenished every billing cycle.";
  const buttonText = isSubscriptionActive ? "Upgrade Plan" : "Subscribe";

  const handleClose = () => {
    logEvent('Out of Credits Dialog Closed', { isSubscriptionActive });
    onClose();
  };

  const handleAction = () => {
    logEvent('Out of Credits Dialog Action Clicked', { 
      isSubscriptionActive, 
      action: isSubscriptionActive ? 'upgrade' : 'subscribe' 
    });
    navigate('/pricing');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            <span>{title}</span>
            <Button variant="ghost" size="icon" onClick={handleClose}>
              <X className="h-4 w-4" />
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="bg-[#f05477]/10 border border-[#f05477]/20 rounded-lg p-3 mb-4 hover:bg-[#f05477]/15 transition-colors duration-300">
            <p className="text-sm font-medium text-[#f05477]">
              🎉 Limited Time Offer: Get the Musician Plan for just $9.99/mo (29% off)! Lock in this special price while you can.
            </p>
          </div>

          <div className="flex items-center space-x-4 mb-4">
            <div className="bg-primary/10 p-3 rounded-full">
              <CreditCard className="h-6 w-6 text-primary" />
            </div>
            <p className="text-muted-foreground flex-1">
              {message}
            </p>
          </div>
        </CardContent>
        <CardFooter className="flex justify-end space-x-2">
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button 
            onClick={handleAction}
            className="bg-[#f05477] hover:bg-[#d03357] text-white transition-colors duration-300"
          >
            {buttonText}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default OutOfCreditsDialog;
