import React from 'react';
import { Link } from 'react-router-dom';
import { Bot, Home } from 'lucide-react';
import { Button } from './ui/button';

function NotFound() {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gradient-to-b from-background to-muted">
      <Bot className="h-24 w-24 text-primary mb-4 animate-bounce" />
      <h1 className="text-4xl font-bold mb-2">404</h1>
      <p className="text-xl mb-8">Oops! We couldn't find that page.</p>
      <Button asChild>
        <Link to="/" className="flex items-center">
          <Home className="mr-2 h-4 w-4" />
          Go Home
        </Link>
      </Button>
    </div>
  );
}

export default NotFound;