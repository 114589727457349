import React from 'react';
import { Bot } from 'lucide-react';

function LoadingSpinnerBig() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="text-center relative">
        <div className="animate-spin rounded-full h-24 w-24 border-t-2 border-b-2 border-primary"></div>
        <Bot className="h-12 w-12 text-primary absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    </div>
  );
}

export default LoadingSpinnerBig;