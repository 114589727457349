import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import MidiPlayer from './MidiPlayer';
import { supabase } from '../lib/supabase';
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { ScrollArea } from "./ui/scroll-area"
import { Send, RefreshCw, Loader2, User, Bot, Undo, ChevronLeft, ChevronRight, Upload, X } from 'lucide-react'; // Import User, Bot, Undo, ChevronLeft, and ChevronRight icons
import LoadingSpinner from './LoadingSpinner';
import OutOfCreditsDialog from './OutOfCreditsDialog';
import EmptyState from './EmptyState';
import { callFunction } from '../lib/functionCaller';
import { logEvent } from '../lib/amplitude';
import ClearHistoryDialog from './ClearHistoryDialog';
import UndoDialog from './UndoDialog';


const MAX_RETRIES = 3;

const musicalLoadingMessages = [
  "Tuning the guitar...",
  "Warming up the orchestra...",
  "Polishing the piano keys...",
  "Rosining the violin bow...",
  "Clearing the conductor's throat...",
  "Adjusting the drum skins...",
  "Oiling the trombone slides...",
  "Dusting off the sheet music...",
  "Tightening the harp strings...",
  "Warming up the vocal cords...",
  "Calibrating the metronome...",
  "Arranging the choir...",
  "Preparing the stage...",
  "Setting up the microphones...",
  "Shuffling the playlist...",
  "Charging up the synthesizer...",
  "Polishing the brass section...",
  "Aligning the woodwinds...",
  "Synchronizing the rhythm section..."
];

const fetchChatHistory = async (userId) => {
  try {
    const { data: chatHistory, error: chatError } = await supabase
      .from('chat_history')
      .select(`
        *,
        midi_files (
          file_path,
          description,
          lilypond_code,
          suggestions
        )
      `)
      .eq('user_id', userId)
      .eq('chat_type', 'midi')  // Add this line
      .order('created_at', { ascending: true });

    if (chatError) throw chatError;

    const combinedHistory = chatHistory.map(message => ({
      ...message,
      role: message.role,
      content: typeof message.message === 'string' ? message.message : JSON.stringify(message.message),
      midiAttachment: message.midi_attachment_name,
      filePath: message.midi_files?.file_path || null,
      description: message.midi_files?.description || null,
      lilypond_code: message.midi_files?.lilypond_code || null,
      suggestions: message.midi_files?.suggestions ? JSON.parse(message.midi_files.suggestions) : []
    }));

    return combinedHistory;
  } catch (error) {
    console.error('Error fetching chat history');
    return [];
  }
};

function Chat({ credits, updateCredits, onPurchaseSubscription, onTopUpPurchase, isSubscriptionActive }) {
  const { user, signOut } = useAuth();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [currentLilyPondCode, setCurrentLilyPondCode] = useState('');
  const scrollAreaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isClearing, setIsClearing] = useState(false);
  const [isOutOfCreditsDialogOpen, setIsOutOfCreditsDialogOpen] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [isWaitingForNewSuggestions, setIsWaitingForNewSuggestions] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isClearConfirmOpen, setIsClearConfirmOpen] = useState(false);
  const [isInputFlashing, setIsInputFlashing] = useState(false);
  const [loadingIntervalId, setLoadingIntervalId] = useState(null);
  const [previousLilyPondCode, setPreviousLilyPondCode] = useState('');
  const MAX_CHARS = 150; // Set the maximum character limit
  const [isUndoDialogOpen, setIsUndoDialogOpen] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [attachedMidiFile, setAttachedMidiFile] = useState(null);

  const initialSuggestions = [
    "Create a simple chord progression",
    "Play me I–V–VI–IV chords in C major",
    "What do VI–IV–I–V chords with seventh notes look like?",
    "Give me a melody with a complementing countermelody",
    "Generate a catchy melody in F major",
    "Play a somber chord progression",
  ];

  // Add this new function to check if there's any history to clear
  const hasHistoryToClear = messages.length > 0;

  // Add this helper function to count assistant messages
  const countAssistantMessages = (messages) => {
    return messages.filter(message => message.role === 'assistant').length;
  };

  useEffect(() => {
    const fetchSession = async () => {
      if (user) {
        setIsLoadingHistory(true);
        try {
          const history = await fetchChatHistory(user.id);
          setMessages(history);
          if (history.length > 0) {
            const lastMessage = history[history.length - 1];
            setCurrentLilyPondCode(lastMessage.lilypond_code || '');
            setPreviousLilyPondCode(lastMessage.lilypond_code || '');
            
            // Only include "Undo" if there's more than one assistant message
            const suggestions = lastMessage.suggestions || initialSuggestions;
            if (countAssistantMessages(history) > 1) {
              setCurrentSuggestions(["Undo", ...suggestions.slice(0, 5)]);
            } else {
              setCurrentSuggestions(suggestions.slice(0, 5));
            }
          } else {
            setCurrentSuggestions(initialSuggestions);
          }

          logEvent('Chat Session Started', { 
            hasExistingHistory: history.length > 0
          });
        } catch (error) {
          console.error('Error fetching chat history');
          setCurrentSuggestions(initialSuggestions);
        } finally {
          setIsLoadingHistory(false);
        }
      } else {
        setCurrentSuggestions(initialSuggestions);
        setIsLoadingHistory(false);
      }
    };

    fetchSession();

    // ... rest of the useEffect
  }, [user]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const displayedSuggestions = windowWidth < 640 ? currentSuggestions.slice(0, 3) : currentSuggestions;

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const cycleLoadingMessage = () => {
    const randomIndex = Math.floor(Math.random() * musicalLoadingMessages.length);
    setLoadingMessageIndex(randomIndex);
  };

  const generateMidi = async (inputText, midiAttachment = null, retryCount = 0) => {
    if (retryCount === 0) {
      setIsLoading(true);
      cycleLoadingMessage();
      const newIntervalId = setInterval(cycleLoadingMessage, 2000);
      setLoadingIntervalId(newIntervalId);
      const userMessage = { 
        role: 'user', 
        content: inputText,
        midiAttachment: midiAttachment ? midiAttachment.name : null
      };
      setMessages(prev => [...prev, userMessage]);
      setPreviousLilyPondCode(currentLilyPondCode);
    }

    try {
      if (!user) throw new Error('No active session');

      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No active session');

      let data;
   
      const invokeData = await callFunction('generate-midi', {
        messages: [...messages, { 
          role: 'user', 
          content: inputText,
          midiAttachment: midiAttachment ? midiAttachment.name : null
        }],
        currentLilyPondCode,
        userId: user.id,
        midiAttachment: midiAttachment ? midiAttachment.base64 : null
      });
      
      data = invokeData;

      logEvent('MIDI Generated', { 
        messageCount: messages.length,
        lilyPondCodeLength: data.lilypondCode.length,
      });

      const botMessage = { 
        role: 'assistant', 
        content: data.description,
        midiData: new Uint8Array(data.midiData),
        description: data.description,
        filePath: data.filePath
      };

      setMessages(prev => [...prev, botMessage]);
      setCurrentLilyPondCode(data.lilypondCode);
      setCurrentSuggestions(["Undo", ...(data.suggestions || initialSuggestions)]);
      setIsWaitingForNewSuggestions(false);
      setIsLoading(false);

      // On success, clear the interval and reset loading state
      clearInterval(loadingIntervalId);
      setLoadingIntervalId(null);
      setIsLoading(false);

      return { newCreditBalance: data.newCreditBalance };
    } catch (error) {
      console.error('Error generating MIDI');
      if (retryCount < MAX_RETRIES) {
        return generateMidi(inputText, midiAttachment, retryCount + 1);
      } else {
        // On final failure, clear the interval and reset loading state
        clearInterval(loadingIntervalId);
        setLoadingIntervalId(null);
        setIsLoading(false);

        const errorMessage = { 
          role: 'assistant', 
          content: "I'm sorry, but I encountered an issue while generating the MIDI. Please try again with a different request or rephrase your input.",
          isError: true
        };
        logEvent('MIDI Generation Error', { error: error.message });
        setMessages(prev => [...prev, errorMessage]);
        setIsWaitingForNewSuggestions(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= MAX_CHARS) {
      setInput(inputText);
    }
  };

  const handleSubmit = async (e, suggestedInput = null) => {
    console.log("handleSubmit called");
    if (e) e.preventDefault();
    const inputText = suggestedInput || input.trim();
    
    if (!inputText && !attachedMidiFile) return;
    
    logEvent('Chat Message Sent', { 
      messageLength: inputText.length, 
      message: inputText,
      hasMidiAttachment: !!attachedMidiFile 
    });

    if (!user) {
      // Handle not logged in case
      return;
    }
    if (credits < 1) {
      setIsOutOfCreditsDialogOpen(true);
      logEvent('Out of Credits Dialog Opened', { isSubscriptionActive });
      return;
    }

    setIsLoading(true);
    try {
      const result = await generateMidi(inputText, attachedMidiFile);
      updateCredits(result.newCreditBalance);
      setInput('');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setAttachedMidiFile(null);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearHistory = async () => {
    if (!user) return;

    setIsClearing(true);
    setIsClearConfirmOpen(false);
    try {
      // Clear chat history from Supabase
      const { data: deletedChatHistory, error: chatError } = await supabase
        .from('chat_history')
        .delete()
        .eq('user_id', user.id)
        .eq('chat_type', 'midi');

      if (chatError) {
        console.error('Error clearing chat history');
        throw chatError;
      }

      // Clear generated MIDI files from storage
      const { data: midiFiles, error: listError } = await supabase
        .storage
        .from('midi-files')
        .list(user.id);

      // Clear uploaded MIDI files from storage
      const { data: uploadedMidiFiles, error: listUploadedError } = await supabase
        .storage
        .from('midi-files')
        .list(`${user.id}/uploads`);

      if (listError) {
        console.error('Error listing generated MIDI files');
        throw listError;
      }

      if (listUploadedError) {
        console.error('Error listing uploaded MIDI files');
        throw listUploadedError;
      }

      // Combine both generated and uploaded files for deletion
      const filesToRemove = [
        ...(midiFiles?.map(file => `${user.id}/${file.name}`) || []),
        ...(uploadedMidiFiles?.map(file => `${user.id}/uploads/${file.name}`) || [])
      ];

      if (filesToRemove.length > 0) {
        const { data: deletedFiles, error: deleteError } = await supabase
          .storage
          .from('midi-files')
          .remove(filesToRemove);

        if (deleteError) {
          console.error('Error deleting MIDI files');
          throw deleteError;
        }
      }

      // Clear MIDI file metadata from Supabase
      const { data: deletedMidiMetadata, error: midiMetadataError } = await supabase
        .from('midi_files')
        .delete()
        .eq('user_id', user.id);

      if (midiMetadataError) {
        console.error('Error clearing MIDI metadata');
        throw midiMetadataError;
      }

      // Clear frontend state
      setMessages([]);
      setCurrentLilyPondCode('');
      setCurrentSuggestions(initialSuggestions);

      logEvent('MIDI Chat History Cleared', { 
        messageCount: messages.length,
        midiFileCount: (midiFiles?.length || 0) + (uploadedMidiFiles?.length || 0)
      });

    } catch (error) {
      console.error('Error clearing chat history and MIDI files');
    } finally {
      setIsClearing(false);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    if (credits < 1) {
      setIsOutOfCreditsDialogOpen(true);
      logEvent('Out of Credits Dialog Opened', { isSubscriptionActive, fromSuggestion: true });
      return;
    }

    if (suggestion === "Undo") {
      setIsUndoDialogOpen(true);
    } else {
      if (messages.length === 0) {
        setIsWaitingForNewSuggestions(true);
        setCurrentSuggestions([]); // Clear current suggestions
        await handleSubmit(null, suggestion);
        setIsWaitingForNewSuggestions(false);
      } else {
        setInput(suggestion);
        setIsInputFlashing(true);
        setTimeout(() => setIsInputFlashing(false), 300);
      }
    }

    if (messages.length > 0) {
      const inputElement = document.querySelector('input[type="text"]');
      if (inputElement) {
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    logEvent('Suggestion Clicked', { 
      suggestion,
      isInitialSuggestion: messages.length === 0,
      isUndo: suggestion === "Undo"
    });
  };

  const handlePurchaseCredits = () => {
    setIsOutOfCreditsDialogOpen(false);
    onPurchaseSubscription();
  };

  const handleTopUpPurchase = (amount) => {
    setIsOutOfCreditsDialogOpen(false);
    onTopUpPurchase(amount);
  };

  const handleOutOfCreditsDialogClose = () => {
    setIsOutOfCreditsDialogOpen(false);
    if (messages.length === 0) {
      setCurrentSuggestions(initialSuggestions);
    }
  };

  // Don't forget to clear the interval when the component unmounts
  useEffect(() => {
    return () => {
      if (loadingIntervalId) {
        clearInterval(loadingIntervalId);
      }
    };
  }, [loadingIntervalId]);

  const handleUndo = async () => {
    const undoPrompt = "Undo the last change and revert to the previous state.";
    setInput(undoPrompt);
    setCurrentLilyPondCode(previousLilyPondCode);
    
    // Log the undo event
    logEvent('Undo Action Performed', {
      previousCodeLength: previousLilyPondCode.length,
      messageCount: messages.length
    });

    await handleSubmit(null, undoPrompt);
    setIsUndoDialogOpen(false); // Close the dialog after undoing
  };

  const suggestionsRef = useRef(null);

  const checkScrollability = () => {
    if (suggestionsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = suggestionsRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    checkScrollability();
    window.addEventListener('resize', checkScrollability);
    return () => window.removeEventListener('resize', checkScrollability);
  }, [currentSuggestions]);

  const handleWheel = (e) => {
    if (e.deltaY !== 0 && suggestionsRef.current) {
      e.preventDefault();
      suggestionsRef.current.scrollLeft += e.deltaY;
      checkScrollability();
    }
  };

  const scrollSuggestions = (direction) => {
    if (suggestionsRef.current) {
      const scrollAmount = 200;
      suggestionsRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(checkScrollability, 300); // Check after scroll animation
    }
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (e.g., 5MB limit)
    const MAX_FILE_SIZE = 1 * 1024 * 1024;
    if (file.size > MAX_FILE_SIZE) {
      alert('File size must be less than 1MB');
      logEvent('MIDI Upload Failed', { 
        reason: 'file_size_exceeded',
        fileSize: file.size,
        maxSize: MAX_FILE_SIZE
      });
      return;
    }

    // Check file type
    if (!file.type.includes('midi') && !file.type.includes('mid')) {
      alert('Only MIDI files are allowed');
      logEvent('MIDI Upload Failed', { 
        reason: 'invalid_file_type',
        fileType: file.type
      });
      return;
    }

    // Convert file to base64
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64String = event.target.result.split(',')[1];
      setAttachedMidiFile({
        name: file.name,
        base64: base64String
      });
      logEvent('MIDI File Attached', {
        fileName: file.name,
        fileSize: file.size,
        isReplacement: !!attachedMidiFile // Track if this is a replacement
      });
    };
    reader.readAsDataURL(file);
  };

  // Add a reference for the file input
  const fileInputRef = useRef(null);

  // Add a click handler for the upload button
  const handleUploadClick = () => {
    logEvent('Upload MIDI Button Clicked', {
      action: attachedMidiFile ? 'replace' : 'new_upload'
    });
    fileInputRef.current?.click();
  };

  // Update the remove attachment handler
  const handleRemoveAttachment = () => {
    logEvent('MIDI File Removed', {
      fileName: attachedMidiFile?.name
    });
    setAttachedMidiFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleEmptyStateAction = async (suggestion, file = null) => {
    if (file) {
      // Handle file upload from EmptyState
      handleFileSelect({ target: { files: [file] } });
      // Scroll to input
      const inputElement = document.querySelector('input[type="text"]');
      if (inputElement) {
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } else {
      // Use existing suggestion handler for text suggestions
      handleSuggestionClick(suggestion);
    }
  };

  return (
    <div className="h-full flex flex-col bg-transparent">
      <ScrollArea className="flex-grow overflow-y-auto" ref={scrollAreaRef}>
        <div className="max-w-1xl mx-auto px-4 py-6 space-y-6 h-full">
          {isLoadingHistory || isClearing ? (
            <div className="flex justify-center items-center h-full">
              <LoadingSpinner text={isClearing ? "Clearing chat history..." : "Loading chat history..."} />
            </div>
          ) : messages.length === 0 ? (
            <EmptyState 
              suggestions={initialSuggestions} 
              onSuggestionClick={handleEmptyStateAction}
            />
          ) : (
            messages.map((message, index) => (
              <React.Fragment key={index}>
                <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                  <div className={`flex items-start space-x-3 ${message.role === 'user' ? 'flex-row-reverse space-x-reverse' : 'flex-row'} max-w-[85%] sm:max-w-[75%] md:max-w-[600px]`}>
                    <div className={`flex-shrink-0 rounded-full p-2 ${message.role === 'user' ? 'bg-primary text-primary-foreground' : 'bg-muted'}`}>
                      {message.role === 'user' ? <User className="h-5 w-5" /> : <Bot className="h-5 w-5" />}
                    </div>
                    <div className={`rounded-lg p-4 ${message.role === 'user' ? 'bg-primary text-primary-foreground' : 'bg-gray-100'} overflow-hidden w-full`}>
                      <p className="break-words">{message.content || "No content"}</p>
                      {message.role === 'user' && message.midiAttachment && (
                        <div className="mt-2 flex items-center space-x-2 text-sm opacity-80">
                          <Upload className="h-3 w-3" />
                          <span>Attached: {message.midiAttachment}</span>
                        </div>
                      )}
                      {message.role === 'assistant' && message.filePath && (
                        <div className="mt-4 w-full max-w-[300px] sm:max-w-full mx-auto">
                          <MidiPlayer 
                            filePath={message.filePath} 
                            onPlay={() => logEvent('MIDI Playback Started', { filePath: message.filePath })}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          )}
          {isLoading && (
            <div className="flex justify-start">
              <div className="flex items-start space-x-3 max-w-[85%]">
                <div className="flex-shrink-0 rounded-full p-2 bg-muted">
                  <Bot className="h-5 w-5" />
                </div>
                <div className="rounded-lg p-4 bg-gray-100 overflow-hidden">
                  <LoadingSpinner text={musicalLoadingMessages[loadingMessageIndex]} />
                </div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </ScrollArea>
      
    

      <div className="bg-transparent">
        <div className="max-w-1xl mx-auto px-4 py-4">
            {/* Only show suggestions above chatbox when there are messages and not clearing history */}
      {messages.length > 0 && currentSuggestions.length > 0 && !isWaitingForNewSuggestions && !isClearing && (
        <div className="relative">
          <div className="absolute left-0 top-0 bottom-0 w-12 bg-gradient-to-r from-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 bottom-0 w-12 bg-gradient-to-l from-white to-transparent z-10"></div>
          <div 
            ref={suggestionsRef}
            className="overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent px-12" 
            style={{ WebkitOverflowScrolling: 'touch' }}
            onWheel={handleWheel}
            onScroll={checkScrollability}
          >
            <div className="flex flex-nowrap gap-2 pb-2" style={{ minWidth: 'min-content' }}>
              {displayedSuggestions.map((suggestion, sugIndex) => (
                suggestion === "Undo" ? (
                  <Button
                    key={sugIndex}
                    onClick={() => handleSuggestionClick(suggestion)}
                    variant="outline"
                    size="sm"
                    className="text-xs sm:text-sm py-1 px-3 rounded-full text-black bg-white border-2 border-black hover:bg-black hover:text-white transition-colors duration-300 whitespace-nowrap flex-shrink-0 shadow-sm flex items-center space-x-1"
                  >
                    <Undo className="h-3 w-3" />
                    <span>{suggestion}</span>
                  </Button>
                ) : (
                  <Button
                    key={sugIndex}
                    onClick={() => handleSuggestionClick(suggestion)}
                    variant="outline"
                    size="sm"
                    className="text-xs sm:text-sm py-1 px-3 rounded-full text-[#f05477] bg-white border-2 border-[#f05477] hover:bg-[#f05477] hover:text-white transition-colors duration-300 whitespace-nowrap flex-shrink-0 shadow-sm"
                  >
                    {suggestion}
                  </Button>
                )
              ))}
            </div>
          </div>
          {canScrollLeft && (
            <button
              onClick={() => scrollSuggestions('left')}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/80 rounded-full p-1.5 shadow-md z-20 transition-colors duration-200"
              aria-label="Scroll left"
            >
              <ChevronLeft className="h-5 w-5 text-white" />
            </button>
          )}
          {canScrollRight && (
            <button
              onClick={() => scrollSuggestions('right')}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/80 rounded-full p-1.5 shadow-md z-20 transition-colors duration-200"
              aria-label="Scroll right"
            >
              <ChevronRight className="h-5 w-5 text-white" />
            </button>
          )}
        </div>
      )}
          <div className="flex space-x-2">
            <form onSubmit={(e) => handleSubmit(e)} className="flex space-x-2 flex-grow">
              <div className="relative flex-grow flex space-x-2">
                <div className="relative flex-grow">
                  <Input
                    type="text"
                    value={input}
                    onChange={handleInputChange}
                    placeholder={
                      attachedMidiFile
                        ? "Enter your instructions..."
                        : windowWidth < 640 
                          ? "Type your desired chords, melody, etc..." 
                          : "Describe your desired chord progression, melody, modifications, etc."
                    }
                    disabled={isLoading}
                    className={`w-full ${attachedMidiFile ? 'pr-[200px]' : 'pr-16'} ${isInputFlashing ? 'input-flash' : ''} text-base sm:text-sm`}
                    onFocus={() => logEvent('Chat Input Focused')}
                    maxLength={MAX_CHARS}
                  />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center space-x-3">
                    {attachedMidiFile && (
                      <div className="flex items-center space-x-2 border border-[#f05477]/20 bg-[#f05477]/5 rounded-md py-1.5 px-3 shadow-sm animate-in slide-in-from-right duration-300">
                        <div className="flex items-center space-x-2">
                          <div className="bg-[#f05477]/10 rounded-full p-1 animate-bounce">
                            <Upload className="h-3 w-3 text-[#f05477]" />
                          </div>
                          <span className="truncate max-w-[100px] text-xs font-medium text-gray-700">
                            {attachedMidiFile.name}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={handleRemoveAttachment}
                          disabled={isLoading}
                          className={`rounded-full p-1 transition-colors ${
                            isLoading 
                              ? 'opacity-50 cursor-not-allowed' 
                              : 'hover:bg-[#f05477]/10'
                          }`}
                          aria-label="Remove attachment"
                        >
                          <X className="h-3 w-3 text-[#f05477]" />
                        </button>
                      </div>
                    )}
                    <span className="text-xs text-gray-400 flex-shrink-0">
                      {input.length}/{MAX_CHARS}
                    </span>
                  </div>
                </div>
                <label className="cursor-pointer flex-shrink-0">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".mid,.midi"
                    onChange={handleFileSelect}
                    className="hidden"
                  />
                  <Button
                    type="button"
                    variant="outline"
                    className={`px-2 py-2 flex items-center space-x-1 ${
                     'bg-primary text-primary-foreground' 
                    }`}
                    disabled={isLoading}
                    onClick={handleUploadClick}
                  >
                    <Upload className="h-4 w-4" />
                    <span className="hidden sm:inline text-sm">
                      {attachedMidiFile ? 'Replace MIDI' : 'Upload MIDI'}
                    </span>
                  </Button>
                </label>
              </div>
              <Button 
                type="submit" 
                disabled={isLoading || (!input.trim())}
                className="w-10 h-10 sm:w-auto sm:h-auto px-2 sm:px-4 py-2 text-sm font-medium text-white bg-[#f05477] hover:bg-[#d03357] rounded-md transition-colors duration-300 flex items-center justify-center sm:justify-start space-x-0 sm:space-x-2 shadow-sm"
              >
                {isLoading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <>
                    <Send className="h-5 w-5" />
                    <span className="hidden sm:inline">Send</span>
                  </>
                )}
              </Button>
            </form>
            <Button 
              onClick={() => setIsClearConfirmOpen(true)}
              variant="outline"
              disabled={isClearing || !hasHistoryToClear}
              className="w-10 h-10 sm:w-auto sm:h-auto px-2 sm:px-4 py-2 text-sm font-medium text-black bg-white border-2 border-black rounded-md hover:bg-black hover:text-white transition-colors duration-300 flex items-center justify-center sm:justify-start space-x-0 sm:space-x-2 shadow-sm"
            >
              {isClearing ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                <>
                  <RefreshCw className="h-5 w-5" />
                  <span className="hidden sm:inline">Clear</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </div>

      <ClearHistoryDialog 
        isOpen={isClearConfirmOpen}
        onClose={() => setIsClearConfirmOpen(false)}
        onClear={handleClearHistory}
        isClearing={isClearing}
      />

      <OutOfCreditsDialog 
        isOpen={isOutOfCreditsDialogOpen}
        onClose={handleOutOfCreditsDialogClose}
        onPurchase={handlePurchaseCredits}
        // onTopUp={handleTopUpPurchase}
        isSubscriptionActive={isSubscriptionActive}
      />

      <UndoDialog 
        isOpen={isUndoDialogOpen}
        onClose={() => setIsUndoDialogOpen(false)}
        onUndo={handleUndo}
      />
    </div>
  );
}

export default Chat;