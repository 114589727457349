import React, { useState, useEffect } from 'react';
import { Button } from "./ui/button";
import { ChevronLeft, ChevronRight, Music, Repeat, Sliders, Layers, Zap, X, Download, MessageSquare } from 'lucide-react';
import { logEvent } from '../lib/amplitude';

const carouselItems = [
  {
    icon: <MessageSquare className="h-12 w-12 mb-4" />,
    title: "Chat-Based MIDI Creation",
    description: "Interact with MidiMate through a simple chat interface to create and refine your musical ideas."
  },
  {
    icon: <Music className="h-12 w-12 mb-4" />,
    title: "Create Chord Progressions",
    description: "Ask MidiMate to generate unique chord sequences with a simple text prompt."
  },
  {
    icon: <Layers className="h-12 w-12 mb-4" />,
    title: "Build Complex Compositions",
    description: "Request melodies, basslines, and harmonies to enhance your progression."
  },
  {
    icon: <Sliders className="h-12 w-12 mb-4" />,
    title: "Fine-tune Your Creation",
    description: "Tell MidiMate to adjust keys, modify chords, or tweak individual notes."
  },
  {
    icon: <Repeat className="h-12 w-12 mb-4" />,
    title: "Iterate and Explore",
    description: "Ask for variations or refinements to explore different musical ideas."
  },
  {
    icon: <Zap className="h-12 w-12 mb-4" />,
    title: "Instant MIDI Playback",
    description: "Hear your compositions instantly with built-in MIDI playback."
  },
  {
    icon: <Download className="h-12 w-12 mb-4" />,
    title: "Export and Use Anywhere",
    description: "Download your MIDI files and use them in any DAW with your favorite VSTs and instruments."
  }
];

function OnboardingCarousel({ onClose }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    logEvent('Onboarding Carousel Opened');
  }, []);

  const nextSlide = () => {
    const newSlide = (currentSlide + 1) % carouselItems.length;
    setCurrentSlide(newSlide);
    logEvent('Onboarding Carousel Slide Changed', { 
      fromSlide: currentSlide,
      toSlide: newSlide,
      direction: 'next'
    });
    if (newSlide === 0) {
      logEvent('Onboarding Carousel Completed');
    }
  };

  const prevSlide = () => {
    const newSlide = (currentSlide - 1 + carouselItems.length) % carouselItems.length;
    setCurrentSlide(newSlide);
    logEvent('Onboarding Carousel Slide Changed', { 
      fromSlide: currentSlide,
      toSlide: newSlide,
      direction: 'prev'
    });
  };

  const handleClose = () => {
    logEvent('Onboarding Carousel Closed', { lastViewedSlide: currentSlide });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto relative">
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-2 right-2"
          onClick={handleClose}
        >
          <X className="h-4 w-4" />
        </Button>
        <div className="text-center mb-8">
          {carouselItems[currentSlide].icon}
          <h2 className="text-2xl font-bold mb-2">{carouselItems[currentSlide].title}</h2>
          <p className="text-gray-600">{carouselItems[currentSlide].description}</p>
        </div>
        <div className="flex justify-between items-center">
          <Button onClick={prevSlide} variant="outline" size="icon">
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <div className="flex space-x-2">
            {carouselItems.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentSlide ? 'bg-primary' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
          <Button onClick={nextSlide} variant="outline" size="icon">
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
        <Button 
          onClick={handleClose} 
          className="w-full mt-6 bg-[#f05477] hover:bg-[#d03357] text-white transition-colors duration-300"
        >
          Got it
        </Button>
      </div>
    </div>
  );
}

export default OnboardingCarousel;