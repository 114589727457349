import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { callFunction } from '../lib/functionCaller';
import LoadingSpinnerBig from './LoadingSpinnerBig';

const BoardToken = '516e2f36-18a6-5924-3757-0561ab04699b';

const FeedbackWidget = ({ onClose }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  const handleCannyError = useCallback((err) => {
    console.error('Canny error:', err);
  }, []);

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 3;
    let cannyInitInterval;

    const initCanny = async () => {
      try {
        setIsLoading(true);
        // Canny SDK loader
        // eslint-disable-next-line
        (function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}})(window,document,"canny-jssdk","script");

        cannyInitInterval = setInterval(async () => {
          if (window.Canny && document.querySelector('[data-canny]')) {
            clearInterval(cannyInitInterval);
            
            // Get SSO token from Edge Function
            const data = await callFunction('generate-canny-sso-token', { user });

            // Render the Canny board with SSO
            window.Canny('render', {
              boardToken: BoardToken,
              basePath: null,
              ssoToken: data.ssoToken,
              theme: 'light',
            });

            window.Canny('on', 'error', handleCannyError);
            window.Canny('on', 'close', onClose);

            setIsLoading(false);
          } else if (retryCount >= maxRetries) {
            clearInterval(cannyInitInterval);
            throw new Error('Canny SDK not loaded or container not found');
          } else {
            retryCount++;
          }
        }, 1000);
      } catch (err) {
        console.error('Error initializing Canny:', err);
        setError(err);
        setIsLoading(false);
      }
    };

    initCanny();

    return () => {
      if (cannyInitInterval) {
        clearInterval(cannyInitInterval);
      }
      if (window.Canny) {
        try {
          window.Canny('destroy');
        } catch (err) {
          console.error('Error destroying Canny:', err);
        }
      }
    };
  }, [handleCannyError, onClose, user]);

  if (error) {
    return <div>Error loading feedback widget: {error.message}</div>;
  }

  return (
    <div className="relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
          <LoadingSpinnerBig className="scale-50" />
        </div>
      )}
      <div data-canny className="w-full h-full" />
    </div>
  );
};

export default FeedbackWidget;
