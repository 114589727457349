import React, { useState, useRef, useEffect } from 'react';

const TooltipProvider = ({ children }) => {
  return <>{children}</>;
};

const Tooltip = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isVisible && triggerRef.current && contentRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      contentRef.current.style.top = `${triggerRect.bottom + window.scrollY}px`;
      contentRef.current.style.left = `${triggerRect.left + triggerRect.width / 2}px`;
    }
  }, [isVisible]);

  return React.Children.map(children, child =>
    React.cloneElement(child, { isVisible, setIsVisible, triggerRef, contentRef })
  );
};

const TooltipTrigger = ({ children, setIsVisible, triggerRef }) => {
  return (
    <div
      ref={triggerRef}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
    </div>
  );
};

const TooltipContent = ({ children, isVisible, contentRef }) => {
  return (
    <div
      ref={contentRef}
      className="fixed z-50 px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700"
      role="tooltip"
      style={{
        visibility: isVisible ? 'visible' : 'hidden',
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s',
        transform: 'translateX(-50%)',
        width: '220px', // Adjust this value to make it wider
        maxWidth: '90vw', // Ensure it doesn't overflow on small screens
        zIndex: 1000,
      }}
    >
      {children}
    </div>
  );
};

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };