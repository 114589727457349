// src/components/LegalLinks.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FileText, Shield, Mail } from 'lucide-react';

function LegalLinks() {
  return (
    <div className="fixed bottom-4 right-4 flex space-x-4">
      <Link 
        to="/terms-and-conditions" 
        className="text-gray-500 hover:text-gray-700 transition-colors"
        aria-label="Terms and Conditions"
      >
        <FileText className="h-5 w-5" />
      </Link>

      <Link 
        to="/privacy-policy" 
        className="text-gray-500 hover:text-gray-700 transition-colors"
        aria-label="Privacy Policy"
      >
        <Shield className="h-5 w-5" />
      </Link>

      <Link 
        to="/contact" 
        className="text-gray-500 hover:text-gray-700 transition-colors"
        aria-label="Contact Us"
      >
        <Mail className="h-5 w-5" />
      </Link>
    </div>
  );
}

export default LegalLinks;