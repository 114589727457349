import React from 'react';
import { Undo, X } from 'lucide-react';
import { Button } from "./ui/button"
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "./ui/card"
import { logEvent } from '../lib/amplitude';

function UndoDialog({ isOpen, onClose, onUndo }) {
  if (!isOpen) return null;

  const handleClose = () => {
    logEvent('Undo Dialog Closed');
    onClose();
  };

  const handleUndo = () => {
    logEvent('Undo Confirmed');
    onUndo();
    onClose(); // Close the dialog after undo
  };

  return (
    <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            <span>Undo Last Change</span>
            <Button variant="ghost" size="icon" onClick={handleClose}>
              <X className="h-4 w-4" />
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center space-x-4 mb-4">
            <div className="bg-primary/10 p-3 rounded-full">
              <Undo className="h-6 w-6 text-primary" />
            </div>
            <p className="text-muted-foreground flex-1">
              This action will revert the last change made to your musical composition. Are you sure you want to undo?
            </p>
          </div>
        </CardContent>
        <CardFooter className="flex justify-end space-x-2">
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button 
            onClick={handleUndo}
            className="bg-[#f05477] hover:bg-[#d03357] text-white transition-colors duration-300"
          >
            Undo Last Change
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default UndoDialog;
