import React from 'react';
import { Mail } from 'lucide-react';

function ContactUs() {
  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
      <p className="mb-4">
        If you have any questions or concerns, please don't hesitate to reach out to us.
      </p>
      <div className="flex items-center mb-4">
        <Mail className="mr-2 h-5 w-5" />
        <a href="mailto:support@midimate.com" className="text-blue-600 hover:underline">
          support@midimate.com
        </a>
      </div>
      <p className="text-sm text-gray-600">
        We typically respond to inquiries within 1-2 business days.
      </p>
    </div>
  );
}

export default ContactUs;