import React, { useEffect } from 'react';
import { Button } from "../components/ui/button";
import { Check, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { logEvent } from '../lib/amplitude';

function PricingPage({ onPurchaseSubscription, isSubscriptionActive, onManageBilling, userPlan }) {
  const navigate = useNavigate();
  console.log('userPlan', userPlan);

  // Track page view on mount
  useEffect(() => {
    logEvent('Pricing Page Viewed', {
      isSubscriptionActive,
      currentPlan: userPlan || 'none'
    });
  }, [isSubscriptionActive, userPlan]);

  const handleBackToApp = () => {
    logEvent('Pricing Page Exit', {
      exitType: 'back_to_app',
      timeSpent: getTimeSpent(),
      isSubscriptionActive,
      currentPlan: userPlan || 'none'
    });
    navigate('/app');
  };

  const handlePlanAction = async (planName) => {
    if (!isSubscriptionActive) {
      logEvent('Plan Selected', {
        planName,
        planPrice: plans.find(p => p.name === planName)?.price,
        currentPlan: 'none',
        context: 'new_subscription'
      });
      onPurchaseSubscription(planName);
    } else {
      logEvent('Plan Change Initiated', {
        fromPlan: userPlan,
        toPlan: planName,
        changeType: getPlanValue(planName) > getPlanValue(userPlan) ? 'upgrade' : 'downgrade',
        currentPlanDuration: getUserPlanDuration()
      });
      onManageBilling();
    }
  };

  // Track FAQ interactions
  const handleFAQClick = (question, isExpanding) => {
    logEvent('FAQ Interaction', {
      question,
      action: isExpanding ? 'expanded' : 'collapsed',
      isSubscriptionActive,
      currentPlan: userPlan || 'none'
    });
  };

  // Track support email clicks
  const handleSupportEmailClick = () => {
    logEvent('Support Email Clicked', {
      context: 'pricing_page',
      isSubscriptionActive,
      currentPlan: userPlan || 'none'
    });
  };

  // Track pricing card hover events
  const handlePlanHover = (planName) => {
    logEvent('Plan Card Hover', {
      planName,
      isSubscriptionActive,
      currentPlan: userPlan || 'none'
    });
  };

  const plans = [
    {
      name: 'Basic',
      price: 4.99,
      features: [
        '50 AI Messages per month',
        'Basic MIDI generation',
        'Standard response time',
        'Basic song suggestions',
        'Export as MIDI file'
      ]
    },
    {
      name: 'Musician',
      price: 9.99,
      originalPrice: 13.99,
      popular: true,
      features: [
        '150 AI Messages per month',
        'Upload your own MIDI files',
        'Advanced MIDI generation',
        'Faster response time',
        'Smart suggestions',
        'Export as MIDI file',
        'Lyric generation',
        'Commercial usage rights'
      ]
    },
    {
      name: 'Producer',
      price: 19.99,
      features: [
        '500 AI Messages per month',
        'Upload your own MIDI files',
        'Professional MIDI generation',
        'Fastest response time',
        'Smart suggestions',
        'Export as MIDI file',
        'Commercial usage rights',
        'Advanced lyrics generation',
        'Commercial usage rights',
        'Unlimited chat history',
        'Priority custom support',
        'Early access to upcoming features and improvements'
      ]
    },
  ];

  const faqs = [
  {
      question: "What are MidiMate's subscription plans?",
      answer: "MidiMate offers three subscription plans: Basic, Musician, and Producer. Due to computational costs, we currently don't offer a free trial apart from the initial 5 free messages. However, for just $4.99, you can start with our Basic plan which provides 50 AI messages. The Musician plan offers 150 messages at $9.99 (currently on sale from $13.99), perfect for regular users. For professionals, our Producer plan provides 500 messages at $19.99 with advanced features and priority support."
    },
    {
      question: "What is your refund policy?",
      answer: "We stand behind our service with a 7-day satisfaction guarantee. If you're not completely satisfied with MidiMate within your first 7 days, we'll gladly process a full refund—no questions asked. Simply reach out to our support team at support@midimate.com, and we'll take care of you. Note that this applies to your initial subscription period to ensure you can try our service with confidence."
    },
    {
      question: "Do I get immediate access after paying?",
      answer: "Yes, immediately after payment you'll receive your credits according to the plan you've chosen. You can start generating MIDI files right away through our chat interface. Each message typically generates a response within seconds."
    },
    {
      question: "Is the payment service secure?",
      answer: "Yes, our payment service is secure. We use Stripe for financial processing, and we do not store any information about your card. Stripe ensures bank-level security standards."
    },
    {
      question: "Can I get a receipt or invoice?",
      answer: "Yes, after payment you'll automatically receive a receipt and invoice."
    },
    {
      question: "Is my data secure? Do my MIDI files remain private?",
      answer: "Yes! All MIDI files you generate and your chat history stay private. Your personal data and musical creations are stored securely, and our system is designed to protect your information from unauthorized access."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept payments via credit or debit cards, including MasterCard, VISA, American Express, as well as many other payment methods. Currently, we do not accept PayPal or cryptocurrency."
    },
    {
      question: "Can I upgrade or downgrade my subscription?",
      answer: "Yes, you can upgrade or downgrade your subscription at any time. Simply go to the account menu icon in the top right of the app and select 'Manage Billing' to access Stripe's billing portal where you can switch plans."
    },
    {
      question: "How can I cancel my subscription?",
      answer: "You can cancel your subscription at any time through your account settings. Click on the menu icon in the top right of the app and select 'Manage Billing' to access Stripe's billing portal where you can cancel your subscription. Your access will continue until the end of your current billing period."
    }
  ];

  return (
    <div className="relative min-h-screen bg-background overflow-y-auto">
      <div className="sticky top-0 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 z-10 border-b">
        <div className="container mx-auto px-4">
          <div className="h-14 flex items-center">
            <Button
              variant="ghost"
              onClick={handleBackToApp}
              className="flex items-center"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back to App
            </Button>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8 pb-32">
        <div className="text-center mb-12 mt-4">
          <h1 className="text-4xl font-bold mb-4">Choose your plan</h1>
          <p className="text-muted-foreground">Choose the plan that's right for you and start working with MidiMate!</p>
          
          <div className="mt-6 bg-[#f05477]/10 border border-[#f05477]/20 rounded-lg p-3 inline-block hover:bg-[#f05477]/15 transition-colors duration-300">
            <p className="text-sm font-medium text-[#f05477]">
              🎉 Limited Time Offer: Get the Musician Plan for just $9.99/mo (29% off)! Lock in this special price while you can.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {plans.map((plan) => {
            const isCurrentPlan = isSubscriptionActive && userPlan === plan.name;
            const isUpgrade = isSubscriptionActive && 
              getPlanValue(plan.name) > getPlanValue(userPlan);
            const isDowngrade = isSubscriptionActive && 
              getPlanValue(plan.name) < getPlanValue(userPlan);
            
            return (
              <div 
                key={plan.name} 
                className={`rounded-xl border bg-card p-6 relative ${
                  plan.popular ? 'ring-2 ring-primary' : ''
                }`}
                onMouseEnter={() => handlePlanHover(plan.name)}
              >
                {plan.popular && (
                  <span className="absolute top-0 right-6 -translate-y-1/2 rounded-full bg-primary px-3 py-1 text-xs text-white">
                    Most popular
                  </span>
                )}
                
                <h3 className="text-xl font-semibold">{plan.name}</h3>
                <div className="mt-4 flex items-baseline">
                  <span className="text-3xl font-bold">${plan.price}</span>
                  <span className="ml-1 text-muted-foreground">/mo</span>
                  {plan.originalPrice && (
                    <span className="ml-2 text-muted-foreground line-through">
                      ${plan.originalPrice}/mo
                    </span>
                  )}
                </div>

                <Button 
                  className="w-full mt-6" 
                  variant={plan.popular ? 'default' : 'outline'}
                  onClick={() => handlePlanAction(plan.name)}
                  disabled={isCurrentPlan}
                >
                  {isCurrentPlan 
                    ? 'Current Plan' 
                    : isSubscriptionActive 
                      ? (isUpgrade 
                          ? 'Upgrade'
                          : isDowngrade 
                            ? 'Downgrade'
                            : 'Select')
                      : 'Get Started'}
                </Button>

                <ul className="mt-6 space-y-3">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center text-sm">
                      <Check className="h-4 w-4 text-primary mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        <div className="mt-20 mb-20">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently asked questions</h2>
          
          <div className="max-w-3xl mx-auto">
            <Accordion 
              type="single" 
              collapsible 
              className="w-full"
              onValueChange={(value) => {
                if (value) {
                  const question = faqs.find((_, i) => `item-${i}` === value)?.question;
                  if (question) handleFAQClick(question, true);
                }
              }}
            >
              {faqs.map((faq, index) => (
                <AccordionItem key={index} value={`item-${index}`}>
                  <AccordionTrigger className="text-left">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent>
                    {faq.answer}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>

            <div className="text-center mt-8 text-muted-foreground">
              <p>
                Have other questions? We're here to help!<br />
                <a 
                  href="mailto:support@midimate.com" 
                  className="text-primary hover:underline"
                  onClick={handleSupportEmailClick}
                >
                  support@midimate.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Helper function to determine plan hierarchy
const getPlanValue = (planName) => {
  const planValues = {
    'Basic': 1,
    'Musician': 2,
    'Producer': 3
  };
  return planValues[planName] || 0;
};

// Helper functions for analytics
const getTimeSpent = () => {
  // Implement time tracking logic
  return Math.floor((Date.now() - window.pageLoadTime) / 1000);
};

const getUserPlanDuration = () => {
  // Implement logic to calculate how long user has been on current plan
  return ''; // Return duration in days or months
};

export default PricingPage;