import { supabase } from './supabase';

const isDevelopment = process.env.NODE_ENV === 'development';
const localFunctionUrl = 'http://127.0.0.1:54321/functions/v1';

  
export const callFunction = async (functionName, body) => {
  const { data, error } = await supabase.functions.invoke(functionName, {
    body: body,
  });

  if (error) throw error;
  return data;

};