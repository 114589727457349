import React from 'react';
import { Loader2 } from 'lucide-react';

function LoadingSpinner({ text = "" }) {
  return (
    <div className="flex items-center space-x-2 text-primary">
      <Loader2 className="h-4 w-4 animate-spin" />
      <span>{text}</span>
    </div>
  );
}

export default LoadingSpinner;