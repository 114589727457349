import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Gift, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function PromotionalBanner() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    // Set expiry to next day at midnight
    const tomorrow = new Date();
    tomorrow.setHours(24, 0, 0, 0);
    
    const difference = tomorrow - new Date();
    
    if (difference > 0) {
      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return null;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleClick = (e) => {
    e.stopPropagation(); 
    navigate('/signup');
  };

  if (!timeLeft) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      onClick={handleClick}
      className="bg-[#f05477] hover:bg-[#d03357] text-white py-2 px-4 text-center shadow-md transition-colors cursor-pointer"
      style={{
        position: 'relative',
        zIndex: 1000,
      }}
    >
      <div className="flex items-center justify-center space-x-2">
        <Gift className="h-5 w-5" />
        <p className="text-sm font-medium">
          Limited Time Offer: Sign up today and get 5 free credits! No credit card required.
        </p>
        <div className="flex items-center space-x-1 bg-white/20 rounded-md px-2 py-1">
          <Clock className="h-4 w-4" />
          <span className="text-sm font-mono">
            {String(timeLeft.hours).padStart(2, '0')}:
            {String(timeLeft.minutes).padStart(2, '0')}:
            {String(timeLeft.seconds).padStart(2, '0')}
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default PromotionalBanner;