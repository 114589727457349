import React, { createContext, useContext, useState, useCallback } from 'react';

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((toast) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { ...toast, id }]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));
    }, toast.duration || 3000);
  }, []);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div className="fixed bottom-4 right-4 z-50">
        {toasts.map((toast) => (
          <div
            key={toast.id}
            className={`mb-2 p-4 rounded-md shadow-lg text-white ${
              toast.variant === 'destructive' 
                ? 'bg-[#d03d5f]' 
                : 'bg-[#f05477]'
            } border ${
              toast.variant === 'destructive'
                ? 'border-[#b02d4f]'
                : 'border-[#d03d5f]'
            } max-w-sm w-full transform transition-all duration-300 ease-in-out hover:scale-105`}
          >
            <h3 className="font-bold text-lg mb-1">{toast.title}</h3>
            <p className="text-sm opacity-90">
              {toast.description}
            </p>
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return { toast: context.addToast };
};
