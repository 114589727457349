import React, { useEffect, useRef, useState } from 'react';
import { supabase } from '../lib/supabase';
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { Download, Loader2 } from 'lucide-react'; // Import Loader2 as well
import { logEvent } from '../lib/amplitude';
import { useToast } from "./ui/use-toast";

function MidiPlayer({ filePath }) {
  const { toast } = useToast();
  const playerRef = useRef(null);
  const visualizerRef = useRef(null);
  const [isToneLoaded, setIsToneLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const checkTone = () => {
      if (window.Tone) {
        setIsToneLoaded(true);
      } else {
        setTimeout(checkTone, 100);
      }
    };
    checkTone();
  }, []);

  useEffect(() => {
    if (isToneLoaded && filePath) {
      setIsLoading(true); // Start loading when we have a file path
      const fetchMidiFile = async () => {
        try {
          const { data, error } = await supabase.storage
            .from('midi-files')
            .download(filePath);

          if (error) throw error;

          // Modify MIDI data
          const modifiedData = await modifyMidiData(data);
          const url = URL.createObjectURL(modifiedData);
          
          if (playerRef.current && visualizerRef.current) {
            playerRef.current.src = url;
            playerRef.current.addVisualizer(visualizerRef.current);
            
            if (playerRef.current.isLoaded) {
              setIsLoading(false);
              logEvent('MIDI Player Loaded', { filePath });
            } else {
              playerRef.current.addEventListener('load', () => {
                setIsLoading(false);
                logEvent('MIDI Player Loaded', { filePath });
              }, { once: true });
            }

            // Add event listeners for playback events
            playerRef.current.addEventListener('start', () => {
              logEvent('MIDI Playback Started', { filePath });
            });

            playerRef.current.addEventListener('pause', () => {
              logEvent('MIDI Playback Paused', { filePath });
            });

            playerRef.current.addEventListener('stop', () => {
              logEvent('MIDI Playback Completed', { filePath });
            });
          }
        } catch (error) {
          console.error('Error fetching MIDI file:', error);
          setIsLoading(false);
          logEvent('MIDI Player Error', { filePath, error: error.message });
        }
      };

      fetchMidiFile();
    }
  }, [filePath, isToneLoaded]);

  /**
   * Modifies the MIDI data to ensure all notes are within an audible range and quantized to a grid.
   * This function performs the following operations:
   * 1. Locates the start of MIDI tracks in the file.
   * 2. Scans through all MIDI events.
   * 3. Identifies note-on and note-off events.
   * 4. For any note with a MIDI value below 36 (C2), it transposes it up by one octave.
   * 5. For any note with a MIDI value above 96 (C7), it transposes it down by one octave.
   * 6. Quantizes note events to align with a specified grid.
   * 
   * @param {Blob} data - The original MIDI file data
   * @returns {Blob} - The modified MIDI file data
   */
  const modifyMidiData = async (data) => {
    const midiFile = new Uint8Array(await data.arrayBuffer());
    let offset = 0;
    let timeOffset = 0;
    const TICKS_PER_BEAT = 480;
    const QUANTIZE_GRID = TICKS_PER_BEAT / 16; // 16th notes for quantization
    const MAX_SHIFT = TICKS_PER_BEAT / 8; // Maximum amount to shift a note (1/8th note)
    const LOW_NOTE_THRESHOLD = 36; // C2
    const HIGH_NOTE_THRESHOLD = 96; // C7

    // Find the start of MIDI tracks
    while (offset < midiFile.length - 4) {
      if (midiFile[offset] === 0x4D && midiFile[offset + 1] === 0x54 && 
          midiFile[offset + 2] === 0x72 && midiFile[offset + 3] === 0x6B) {
        offset += 8; // Skip track header and length
        break;
      }
      offset++;
    }

    // // Modify and quantize note events
    while (offset < midiFile.length - 2) {
      // Read delta time
      let deltaTime = 0;
      while (midiFile[offset] & 0x80) {
        deltaTime = (deltaTime << 7) | (midiFile[offset] & 0x7F);
        offset++;
      }
      deltaTime = (deltaTime << 7) | midiFile[offset];
      offset++;

      timeOffset += deltaTime;

      if ((midiFile[offset] & 0xF0) === 0x90 || (midiFile[offset] & 0xF0) === 0x80) {
        // Note on or note off event
        if (midiFile[offset + 1] < LOW_NOTE_THRESHOLD) {
          midiFile[offset + 1] += 12; // Transpose up an octave
        } else if (midiFile[offset + 1] > HIGH_NOTE_THRESHOLD) {
          midiFile[offset + 1] -= 12; // Transpose down an octave
        }

        // // Gentle quantization
        // const nearestGrid = Math.round(timeOffset / QUANTIZE_GRID) * QUANTIZE_GRID;
        // let shift = nearestGrid - timeOffset;
        
        // // Limit the maximum shift to prevent notes from disappearing
        // shift = Math.max(-MAX_SHIFT, Math.min(MAX_SHIFT, shift));
        
        // const quantizedTime = timeOffset + shift;

        // // Adjust the delta time of this event
        // let newDeltaTime = deltaTime + shift;
        // let newDeltaBytes = [];
        // do {
        //   newDeltaBytes.push((newDeltaTime & 0x7F) | (newDeltaBytes.length ? 0x80 : 0));
        //   newDeltaTime >>= 7;
        // } while (newDeltaTime > 0);

        // // Replace the old delta time with the new one
        // midiFile.set(newDeltaBytes.reverse(), offset - newDeltaBytes.length);

        // timeOffset = quantizedTime;
      }

      // Move to next event
      offset += 3; // Skip status byte, note number, and velocity
    }

    return new Blob([midiFile], { type: 'audio/midi' });
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    logEvent('MIDI Download Initiated', { filePath });
    try {
      const { data, error } = await supabase.storage
        .from('midi-files')
        .download(filePath);

      if (error) throw error;

      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'generated_midi.mid';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      
      logEvent('MIDI Download Completed', { filePath });
      
      toast({
        title: "Download Successful",
        description: "Your MIDI file has been downloaded. Drag it into your DAW to import it into your project!",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error downloading MIDI file:', error);
      logEvent('MIDI Download Error', { filePath, error: error.message });
      
      toast({
        title: "Download Failed",
        description: "There was an error downloading your MIDI file. Please try again.",
        variant: "destructive",
        duration: 3000,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  if (!isToneLoaded) {
    return null; // Or you could return a "Loading Tone.js..." message
  }

  return (
    <Card className="w-full mt-2">
      <CardContent className="p-2">
        <div className="midi-player-container space-y-2 relative">
          <div className="overflow-hidden w-full">
            <midi-player
              ref={playerRef}
              sound-font="https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus"
              className="w-full"
              style={{
                minWidth: '0',
                width: '100%',
                fontSize: '12px',
                '--player-height': '40px',
                '--button-width': '40px',
                '--button-height': '40px',
                '--seek-width': 'calc(100% - 80px)',
                '--seek-height': '4px',
                '--seek-thumb-width': '12px',
                '--seek-thumb-height': '12px',
              }}
            ></midi-player>
          </div>
          <div className="visualizer-container overflow-y-auto max-w-full" style={{ maxHeight: '300px' }}> {/* Changed to overflow-y-auto and use inline style for max-height */}
            <midi-visualizer 
              ref={visualizerRef}
              type="piano-roll"
              className="w-full h-full"
              style={{
                minWidth: '0',
                width: '100%',
                fontSize: '8px',
                overflowX: 'hidden',
              }}
            ></midi-visualizer>
          </div>
          {isLoading && (
            <div className="absolute inset-0 flex justify-center items-center bg-background/50 z-10">
              <Loader2 className="h-6 w-6 animate-spin text-primary" />
            </div>
          )}
          <div className="flex justify-end"> {/* New wrapper div for centering */}
            <Button 
              onClick={handleDownload}
              variant="secondary"
              className="text-xs py-1 px-4 w-auto" // Adjusted classes
              disabled={isDownloading}
            >
              {isDownloading ? (
                <>
                  <Loader2 className="mr-1 h-4 w-4 animate-spin" />
                  Downloading...
                </>
              ) : (
                <>
                  <Download className="mr-1 h-4 w-4" /> Download MIDI
                </>
              )}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default MidiPlayer;
